import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Skeleton} from '@material-ui/lab';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
// i18n translation

import LanguageSelect from "../../../../components/language-select/LanguageSelect";

const styles  = theme => ({
    headContainer:{
        padding:'0px 40px',
        margin: '34px 0px',
        // marginBottom:64,
        // [theme.breakpoints.down('xs')]:{
        //     marginBottom:64,
        // }
        [theme.breakpoints.down('xs')]:{
            padding:'0px 20px',
        },
    },
    container:{
        background: theme.palette.background.main,
        width: '100%',
        minHeight:'72vh',
        position:'relative',
        display:'flex',
        flexWrap: 'wrap',
        justifyContent:'center', //space-around
        alignItems:'flex-start',
        padding:'0 40px',
        [theme.breakpoints.down('sm')]:{
            padding:'20px',
            //flexDirection:'column',
            //justifyContent:'flex-start'
        },
        [theme.breakpoints.down('xs')]:{
            padding:0,
        }
    },
    // Define if there is the marginTop 64 (for boxCard that have -48px)
    containerMarginTop:{
        marginTop:80,
    },
    title:{
        fontSize:30,
        fontWeight:600,
        color: theme.palette.primary.main,
    },
    subtitle:{
        fontSize:15,
        marginTop:8,
        color: theme.palette.gray.main,
        marginBottom:40,
    },
    flex: {
        display: 'flex',
        alignItems: 'center', // define the alignment of the BoxCard
        justifyContent: 'center',
        flexWrap: 'wrap',
        width:'100%'
    },
    flexContainer:{
        display : 'flex',
        justifyContent: 'space-between',
        alignItems : 'center',
        [theme.breakpoints.down('xs')]:{
            flexDirection : 'column',
            alignItems : 'flex-start',
        }
    },
    titleRoot:{
        maxWidth : '72%',
            [theme.breakpoints.down('xs')]:{
                maxWidth : '100%'
            }
}
});

class BackofficeViewContainer extends Component {

    // Define the classes of container (if there is the marginTop 64 (for boxCard that have -48px))
    defineMarginTopContainer(hasMarginTop){
        if(hasMarginTop){
            return [this.props.classes.container, this.props.classes.containerMarginTop].join(' ');
        }else{
            return this.props.classes.container
        }
    }

    render() {
        const { classes, background=true , hasMarginTop=true, languageSelect=false, handleChangeLanguage = null, languageValue = null, loader= false} = this.props;

        return (
            <div className={classes.root}>

                <div className={classes.headContainer}>

                    {/* Search Breadcrumbs */}
                    <div>{this.props.renderBreadcrumbs}</div>

                    <div className={classes.flexContainer}>

                    <div className={classes.titleRoot}>
                        <Typography className={classes.title}>
                            {loader ? <Skeleton width={250}/> : this.props.title}
                        </Typography>
                        <Typography className={classes.subtitle}>
                            {loader ? <Skeleton width={125}/> : this.props.subtitle}
                        </Typography>
                    </div>

                        {languageSelect  &&
                            <LanguageSelect
                                onChange={handleChangeLanguage}
                                value={languageValue}
                            />
                        }

                    </div>

                    {/* Search container */}
                    <div>{this.props.renderSearch}</div>

                    {/* Toolbar container */}
                    <div style={{marginTop:20}}>{this.props.renderToolbar}</div>

                </div>
                <div className={this.defineMarginTopContainer(hasMarginTop)} style={(!background) ? {background:'unset'} : null}>
                    <div className={classes.flex}>
                    {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

BackofficeViewContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    hasMarginTop: PropTypes.bool,
    background: PropTypes.bool,
};

const mapStateToProps = state => ({
    router: state.router,
});

export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps)
)(BackofficeViewContainer);
