import React from 'react';
import PropTypes from 'prop-types';
//import material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
import { updateUser } from '../../../../redux/actions/user-action';
// i18n translation

// assets import
import Logo from '../../../../assets/logos/ubilod_logo-light_bg.svg';
// api import
import AuthApi from '../../../../services/AuthApi';
// components import
import TextFieldPasswordStrength from '../../../../components/ui/inputs/TextFieldPasswordStrength';
import {translate} from "../../../../translations/i18n";

const styles = () => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    textField: {
        width: '100%',
        minWidth:300,
        marginTop: 24,
    },
    button: {
        marginRight:16,
        padding: '6px 12px',
        minHeight:20,
        '& > span:first-child':{
            textTransform:'capitalize'
        }
    },
    loader: {
        color: '#fff',
        marginLeft:12,
    },
});

class ChangePasswordDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            old_password: '',
            new_password: '',
            confirm_new_password: '',
            loader: false,
            isPassStrong: false,
        }
    }

    handleOldPasswordChange = (event) => {
        this.setState({old_password: event.target.value})
    }

    handleNewPasswordChange = (event,isPassStrong) => {
        this.setState({
            new_password: event.target.value,
            isPassStrong: isPassStrong
        });
    }
    handleConfirmNewPasswordChange  = (event) => {
        this.setState({confirm_new_password: event.target.value,})
    }

    handleSubmitChangePassword(event) {
        event.preventDefault();
        if(this.state.new_password === this.state.confirm_new_password){
            if(this.state.old_password !== this.state.new_password) {
                if(this.state.isPassStrong){
                    this.setState({
                        loader: true,
                    });
                    AuthApi.changePassword(this.state.old_password, this.state.new_password)
                        .then(
                            () => {
                                this.setState({
                                    loader: false,
                                });
                                this.props.onUpdateUser({
                                    ...this.props.user,
                                    first_access : false
                                });
                                this.props.onUpdateApiControl({status: 200}, 'auth',  'Password cambiata correttamente');
                            },
                            err => {
                                this.setState({loader: false});
                                // ====== API CONTROL MESSAGE ====== //
                                let message = "";
                                if (err.response.status === 400) {
                                    message = 'Assicurati che la nuova password abbia almeno 8 caratteri'
                                } else if (err.response.status === 401) {
                                    message = 'Vecchia password errata'
                                }
                                this.props.onUpdateApiControl(err, 'changePassword',  message);

                            }
                        );
                }else{
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl({status: 400}, 'auth',  'La password non è abbastanza sicura: Assicurati che la nuova password' +
                        ' abbia almeno 8 caratteri tra i quali lettere maiuscole, minuscole e numeri');
                }
            }else{
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl({status: 400}, 'change_password',  'La nuova password non può essere uguale a quella già in uso');
            }
        }else{
            // ====== API CONTROL MESSAGE ====== //
            this.props.onUpdateApiControl({status: 400}, 'change_password',  'Nuova password e conferma nuova password non corrispondono');
        }
    }

    render() {
        const { classes, intl, open=true, loader } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className={classes.root}>
                    <img alt={'logo'} src={Logo} width={180} style={{marginTop:20}}/>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <form onSubmit={(e) => this.handleSubmitChangePassword(e)}
                                  ref="form"
                                  className={classes.formContainer}
                                  autoComplete="off">
                                <TextField
                                    id="old_password"
                                    type="password"
                                    label={translate( 'change_password.old_password', {},  'change_password.old_password')}
                                    className={classes.textField}
                                    value={ this.state.old_password}
                                    onChange={(e) => {this.handleOldPasswordChange(e)}}
                                />
                                <TextFieldPasswordStrength
                                    id="new_password"
                                    type="password"
                                    label={translate( 'change_password.new_password', {},  'change_password.new_password')}
                                    className={classes.textField}
                                    value={this.state.new_password}
                                    onChange={(e, isPassStrong) => {this.handleNewPasswordChange(e, isPassStrong)}}
                                />
                                <TextField
                                    id="confirm_new_password"
                                    type="password"
                                    label={translate( 'change_password.confirm_new_password', {},  'change_password.confirm_new_password')}
                                    className={classes.textField}
                                    value={this.state.confirm_new_password}
                                    onChange={(e) => {this.handleConfirmNewPasswordChange(e)}}
                                    style={{marginTop:0}}
                                />
                                <Button disabled={this.state.old_password === '' || this.state.new_password === '' || this.state.confirm_new_password === ''}
                                        type="submit"
                                        style={{marginTop:20}}
                                        variant="raised"
                                        color="primary"
                                        size="small">
                                    {(!loader) ? <span>
                                        {translate( 'change_password.change_password_btn', {},  'change_password.change_password_btn')}
                                    </span> : null}
                                    {(loader) ? <CircularProgress className={classes.loader} size={16} /> : null}
                                </Button>
                            </form>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary"
                                variant="outlined"
                                onClick={this.props.onClose}
                                className={classes.button}
                                style={{minWidth:140}}>
                            {translate( 'change_password.close_btn', {},  'change_password.close_btn')}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

ChangePasswordDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    api_control: state.api_control
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateUser : updateUser
}


export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps))(ChangePasswordDialog);

