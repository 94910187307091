import React from 'react';
//icons file import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFilePdf, faFileWord, faFilePowerpoint, faFileExcel, faFileAlt, faFileImage, faFileAudio, faFileVideo, faFileArchive, faLink} from '@fortawesome/free-solid-svg-icons';

class FileUtils {

    /**
     * @description Returned the size of the file formatted bytes/kb/mb/gb/tb ecc.
     * @param {bytes} starting bytes size
     * @param {number} decimal number
     * @return {string} Formmatted size
     */
    static bytesToSize = (bytes,decimals=2) => {
        if(bytes === 0) return '0 Bytes';
        var k = 1024,
            dm = decimals || 2,
            sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * @description Returned the extention of the file pdf/jpg/mp4
     * @param {string} filename
     * @return {string} extention
     */
    static getFileExtension = (filename) => {
        return filename && filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
    }

    /**
     * @description Returned the file type image/doc/video/html
     * @param {string} filename
     * @return {string} file type
     */
    static getFileType = (filename) => {
        let extension = FileUtils.getFileExtension(filename);
        switch(extension){
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'bmp':
            case 'gif':
            case 'tif':
            case 'tiff':
                return 'image';
            case 'pdf':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
            case 'csv':
            case 'txt':
            case 'ppt':
            case 'pptx':
            case 'pps':
            case 'odt':
            case 'ods':
            case 'rtf':
            case 'htm':
            case 'html':
            case 'json':
            case 'css':
            case 'js':
                return 'doc';
            case 'mp4':
            case 'flv':
            case 'mov':
            case 'avi':
                return 'video';
            case 'mp3':
            case 'wav':
            case 'ogg':
                return 'audio';
            default: return null
        }
    }

    /**
     * @description Returned the icon of the file
     * @param {string} filename
     * @return {string} file type
     */
    static generalFile = (dimension) => { return ( <FontAwesomeIcon icon={faFile} size="4x" style={{color: 'grey', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static pdf = (dimension) => { return ( <FontAwesomeIcon icon={faFilePdf} size="4x" style={{color: '#ff3c3c', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static word = (dimension) => { return ( <FontAwesomeIcon icon={faFileWord} size="4x" style={{color: '#2c5898', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static powerPoint = (dimension) => { return ( <FontAwesomeIcon icon={faFilePowerpoint} size="4x" style={{color: '#cb1310', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static excel = (dimension) => { return ( <FontAwesomeIcon icon={faFileExcel} size="4x" style={{color: '#00a720', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static text = (dimension) => { return ( <FontAwesomeIcon icon={faFileAlt} size="4x" style={{color: '#848c9e', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static image = (dimension) => { return ( <FontAwesomeIcon icon={faFileImage} size="4x" style={{color: '#009688', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static video = (dimension) => { return ( <FontAwesomeIcon icon={faFileVideo} size="4x" style={{color: '#469cdc', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static audio = (dimension) => { return ( <FontAwesomeIcon icon={faFileAudio} size="4x" style={{color: '#ffd83b', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static archive = (dimension) => { return ( <FontAwesomeIcon  icon={faFileArchive} size="4x" style={{color: '#713b93', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };
    static link = (dimension) => { return ( <FontAwesomeIcon  icon={faLink} size="4x" style={{color: '#713b93', fontSize : (dimension) ? dimension : (window.screen.width > 600) ? '4em' : '2em'}} /> ) };

    static getIcon = (filename, dimension) => {
        let extension = FileUtils.getFileExtension(filename);
        switch(extension) {
            case 'pdf':
                return FileUtils.pdf(dimension);
            case 'doc':
            case 'docx':
                return FileUtils.word(dimension);
            case 'ppt':
            case 'pptx':
                return FileUtils.powerPoint(dimension);
            case 'csv':
            case 'xls':
            case 'xlsx':
                return FileUtils.excel(dimension);
            case 'txt':
            case 'css':
            case 'js':
            case 'json':
            case 'rtf':
            case 'html':
                return FileUtils.text(dimension);
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'bmp':
            case 'gif':
            case 'tif':
            case 'tiff':
                return FileUtils.image(dimension);
            case 'mp4':
            case 'flv':
            case 'mov':
            case 'avi':
                return FileUtils.video(dimension);
            case 'mp3':
            case 'wav':
                return FileUtils.audio(dimension);
            case 'zip':
            case 'tar':
            case 'gz':
            case '7z':
            case 'zipx':
            case 'tgz':
                return FileUtils.archive(dimension);
            case '':
                return FileUtils.link(dimension);
            default:
                return FileUtils.generalFile(dimension);
        }
    };
}

export default FileUtils
