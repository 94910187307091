import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {Info} from 'react-feather'
import { updateApiControl } from '../../../../redux/actions/api-control-action';
// api
import PagesApi from '../../../../services/PagesApi';
import {updateUser} from "../../../../redux/actions/user-action";
import Typography from "@material-ui/core/Typography";
import DashCard from "./components/DashCard";
import {File, Paperclip, Package, Smartphone} from "react-feather";
import moment from "moment";
import FileUtils from "../../../../utils/FileUtils";
import LinearProgress from "@material-ui/core/LinearProgress";
import DashMachineCard from "./components/DashMachineCard";
import Button from "@material-ui/core/Button";
import QrImage from '../../../../assets/immagini/QR-Code-Mock.png'
import {Tooltip} from "@material-ui/core";
import NoLicenseDialog from "./components/NoLicenseDialog";
import {translate} from "../../../../translations/i18n";
import {updateLicense} from "../../../../redux/actions/license-action";
import ChangePasswordFA from "./components/ChangePasswordFA";

const styles  = theme => ({
    root : {
        margin: '34px 0px',
        padding: '0px 40px',
            [theme.breakpoints.down('xs')]: {
                padding: '0px 10px'
        }
    },
    head : {
        margin : '50px 0px'
    },
    title:{
        fontSize:40,
        fontWeight:600,
        color: '#000',
    },
    subtitle:{
        fontSize:20,
        fontWeight:500,
        color: theme.palette.gray.main,
        marginBottom:12,
        textTransform : 'uppercase',
/*
        letterSpacing : '1px',
*/
        display : 'flex',
        alignItems : 'center',
        justifyContent: 'flex-start'
    },
    container:{
        display : 'flex',
        justifyContent : 'space-between',
        alignItems : 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flexWrap : 'wrap'
        }
    },
    columnContainer:{
        width : 'calc((100% / 3) - (48px /3))',
        height : '100%',
        display: 'flex',
        flexDirection : 'column',
        [theme.breakpoints.down('sm')]: {
            width : 'unset'
        },
        [theme.breakpoints.down('xs')]: {
            width : '100%'
        }
    },
    value : {
        fontSize : 28,
        fontWeight : '500',
        lineHeight : 1.2
    },
    text : {
        fontSize: 16,
        color : '#000',
        opacity : 0.4,
        marginBottom: 8
    },
    textContainer:{
        padding : '0px 40px 40px 40px',
        marginTop: '-12px'
},
    barColorPrimary:{
        backgroundColor : '#F19636',
    },
    colorPrimary:{
        backgroundColor : '#eaeaea',
        borderRadius : '8px'
    },
    qrContainer : {
        width : '100%',
        padding : 40,
        borderRadius : 20,
        border: '1px solid #CAD2DA',
        display : 'flex',
        alignItems : 'center',
        backgroundColor: '#2C3A49',
        flexDirection : 'column',
        '&:last-child':{
            margin: '24px 0px',
        },
        '&:first-child':{
            margin: '24px 0px',
        }

    },
    qrTextContainer:{
      display : 'flex',
      flexDirection : 'column',
    },
    qrTitle :  {
        fontSize : 28,
        fontWeight : '500',
        lineHeight : 1.2,
        color : '#fff'
    },
    qrText:{
        fontSize: 16,
        color : '#fff',
    },
    tooltip : {
        fontsize : 16
    },
    btn:{
        backgroundColor : '#F19636',
        fontSize: 14,
        padding: '11px 16px 9px 16px !important',
        borderRadius: 5,
        fontWeight: 'bold',
        width : 'max-content',
        minHeight:20,
        lineHeight: 'unset',
        '& > span:first-child':{
            textTransform:'capitalize',
            paddingBottom: 4,
},
        '&:hover':{
            backgroundColor : '#ba742a',
        },
        [theme.breakpoints.down('xs')]:{
            padding: '6px 18px',
        }
    }
});

class Dashboard extends Component {

    constructor(props){
        super(props);
        this.state = {
            loader: true,
            licenseDialog: false,
            dashboardData: {
                name_product_1 : '',
                name_product_2 : '',
                name_product_3 : '',
                qr_product_1 : 0,
                qr_product_2 : 0,
                qr_product_3 : 0,
            },
        }
    }

    checkLicense = () => {
        const {user} = this.props

        if (user && user.company && user.company.licenses.length > 0 && moment(user.company.licenses[0].expire).isBefore(moment().format('YYYY-MM-DD')))
            this.setState({licenseDialog : 'expired'} )
        else if (user && user.company && user.company.licenses.length > 0 && moment(user.company.licenses[0].expire).isBefore(moment().add(7,'d').format('YYYY-MM-DD')))
            this.setState({licenseDialog : 'warning'})
        else
            this.setState({licenseDialog : false})
    }

    handleCheckDialog = (licenseDialog) => {
        const {user} = this.props


        switch(licenseDialog){
            case 'expired': return true
            case 'warning': return user.warning;
            default : return false;
        }

    }

    handleCloseDialog(licenseDialog){
        if (licenseDialog === 'expired')
            this.props.onUpdateUser({})
            else {
            this.setState({licenseDialog: false})
            this.props.onUpdateUser({
                ...this.props.user,
                warning : false
            })

        }
    }

    componentDidMount(){

        this.checkLicense()

        PagesApi.getHome().then(
            res => {
                this.setState({
                    loader:false,
                    dashboardData: res.data
                });
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'getDashboard', );
            }
        );

        if (this.props.user.company_id) {
            let tmpUser = this.props.user;
            delete tmpUser.company_id;
            this.props.onUpdateUser(tmpUser)
        }
    }

    render() {
        const { dashboardData, licenseDialog } = this.state;
        const { classes, user, license} = this.props;

        const tooltipText = () => {
            switch (license.license) {
                case 'tech':
                    return 'In questa sezione di Ubilod puoi gestire la documentazione tecnica relativa ai modelli, i macchinari e le singole commesse.';
                case 'commercial':
                    return 'In questa sezione di Ubilod puoi creare dei contenitori per gestire materiali commerciali, di comunicazione o necessari ad una demo';
                default:
                    return 'In questa sezione di Ubilod puoi creare dei contenitori per gestire materiali commerciali, di comunicazione o necessari ad una demo';
            }
        };

        return (
            <div className={classes.root}>
                <div className={classes.head}>
                    <div style={{maxWidth : '75%'}}>
                        <Typography className={classes.subtitle}>
                            {license.license}

                            <Tooltip arrow placement={"right"} title={<Typography style={{fontSize:14}}>{tooltipText()}</Typography>}>
                            <Info size={20} style={{marginLeft : 12}}/>
                            </Tooltip>
                        </Typography>
                        <Typography className={classes.title}>
                            {translate('dashboard.welcome', {}, 'Bentornato')} {user.first_name} {user.last_name}
                        </Typography>
                    </div>
                </div>

                <div className={classes.container}>
                    <div className={classes.columnContainer}>
                        <DashCard
                        color={'#C9D2DA'}
                        icon={<File size={32} style={{transform: 'rotate(180deg) scaleX(-1)'}}/>}
                        text={translate('dashboard.qr_used', {}, 'QR code utilizzati')}
                        value={dashboardData && dashboardData.count_product ? dashboardData.count_product : '0'}
                        />
                        <DashCard
                            color={'#FAE6D4'}
                            icon={<Package size={32} color={'#F19636'}/>}
                            text={translate('dashboard.storage_space', {}, `Spazio di \n archiviazione`)}
                        >
                            <div className={classes.textContainer}>
                                <Typography className={classes.value}>{dashboardData && FileUtils.bytesToSize(dashboardData.total_file_size || 0)}</Typography>
                                <Typography className={classes.text}>di {user.company && (FileUtils.bytesToSize(user.company.space_limit* (2**20)))}</Typography>
                                <LinearProgress variant="determinate" value={
                                    // use user.company.space_limit invece che 1073741824  quando i dati vengono passati coretti
                                    dashboardData && dashboardData.total_file_size && user.company ? (dashboardData.total_file_size / (user.company.space_limit * 1000000) ) * 100 : 0}
                                classes={{
                                    barColorPrimary : classes.barColorPrimary,
                                    colorPrimary : classes.colorPrimary
                                }}/>
                            </div>
                        </DashCard>

                        {console.log({license})}

                        <DashCard
                            color={'#FAE6D4'}
                            icon={<Paperclip color={'#F19636'} size={32} />}
                            value={moment(new Date(license.expire)).format('DD/MM/YYYY')}
                            text={translate('dashboard.license', {}, 'Scadenza licenza')}
                        />
                    </div>

                    <div className={classes.columnContainer}>

                        <DashCard
                            color={'#FFF7E5'}
                            icon={<Smartphone size={32} color={'#F9C74F'} />}
                            value={dashboardData.qr_product_tot || '0'}
                            text={translate('dashboard.total_views', {}, 'Visualizzazioni totali')}
                        />

                        <DashMachineCard data={dashboardData} color={'#FFF7E5'}/>
                    </div>

                    <div className={classes.columnContainer}>
                        <div className={classes.qrContainer}>

                            {/*<div style={{backgroundImage : `url(${QrImage})`, backgroundSize : 'cover', width : '41%',height : 200, margin :  '0px 0px 32px 0px'}}/>*/}
                            <img
                                src={QrImage}
                                alt={'Qr-Image'}
                                style={{
                                    height : 200,
                                    width : 200,
                                    margin :  '0px 0px 32px 0px',
                                filter: 'brightness(0) invert(1)',
                            }}
                            />

                            <div className={classes.qrTextContainer}>
                                <Typography className={classes.qrTitle}>
                                    Ottieni il meglio da Ubilod!
                                </Typography>
                                <Typography className={classes.qrText}>
                                    Scansiona il codice qr o clicca il bottone qui sotto per scoprire i tutorial di come sfruttare al meglio Ubilod e tutte le sue funzionalità!
                                </Typography>

                                <a href="https://gestionedocumentale.ubilod.com/?ref=DashUB"
                                   style={{width: 'fit-content', marginTop:32}}
                                   target="_blank" rel="noopener noreferrer">

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn}>
                                    Scopri Ubilod
                                </Button>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                {/* TODO : rimettere questo quando è finita la fiera */}
                {/*{this.handleCheckDialog(licenseDialog) &&
                    <NoLicenseDialog
                    open={this.handleCheckDialog(licenseDialog)}
                    type={licenseDialog}
                    title={
                        licenseDialog === 'expired' ?
                         'È scaduta la tua licenza di Ubilod!'
                            :
                        'Sta per scadere la tua licenza di Ubilod!'
                    }
                    subtitle={
                        licenseDialog === 'expired' ?
                        'Quando questo timer finirà potresti perdere il tuo account e i tuoi dati su Ubilod, se ci tieni ti consigliamo di rinnovare la licenza.'
                        :
                        'Quando questo timer finirà perderai la possibilità di usare Ubilod, se tieni ai tuoi dati e al tuo tempo ti consigliamo di rinnovare la licenza.'
                    }
                    user={user}
                    onClose={() => this.handleCloseDialog(licenseDialog)}
                    />
                }*/}


                {user.first_access && <ChangePasswordFA/>}



            </div>
        )
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
    utils: state.utils,
    api_control: state.api_control
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateUser : updateUser,
    onUpdateLicense : updateLicense,
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(Dashboard);
