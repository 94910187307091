import {createMuiTheme} from "@material-ui/core";
const theme = createMuiTheme({
    palette: {
        primary: {
            background: '#f5f6f7',
            light: '#626f84', //f9aa54
            main: '#2C3A49',
            hover: '#24303d',
            dark: '#2C3A49',
            contrastText: '#fff',
        },
        secondary: {
            light: '#f9aa54',
            main: '#fff',
            dark: '#d9dcde',
            contrastText: '#2C3A4A',
        },
        active:{
            main:'#f9aa54', // orange
            //main:'#83bcec', // light blue
            //main:'#9e2b25' // red
        },
        background: {
            main: '#fff',
        },
        success: {
            main: '#40ce6a',
            dark: '#2cb346'
        },
        warning: {
            main: '#ffcc5e',
            dark: '#ffb340',
        },
        danger: {
            main: '#ff6851',
            dark: '#e41717',
        },
        info: {
            main: '#70cff9',
            dark: '#58c2f9',
        },
        gray: {
            main: '#7d878c',
            secondary: '#b5c3d2',
            dark: '#4b4b4b',
        },
        backgroundBlur: {
            backdropFilter: 'blur(10px) contrast(.1)',
            background: 'rgba(255,255,255,0.88)',
        }
    },
    typography: {
        // Use the system font over Roboto.
        fontSize: 12,
        fontFamily: [
            'Work Sans',
            'sans-serif'
        ].join(','),
        title: {
            fontSize: 20,
        },
        subtitle: {
            fontSize: 20,
        }
    },
    pageContainerPadding: {
        unit: 40
    },
    textFieldMargin: {
        unit: 20
    },
    toolbarHeight: {
        unit: 40
    },
    boxShadow: {
        value: '0px 2px 5px #ccc',
    },
    overrides: {
        MuiPaper:{
            rounded : {
                borderRadius : '10px'
            },
          elevation1 : {
              boxShadow : 'unset'
          },
        },
        MuiButtonBase:{
            root:{
                boxShadow : 'unset !important'
            }
        },
        MuiTouchRipple:{
            display:'none'
        },
        MuiDialog:{
            container:{
                background: 'rgba(43, 58, 73, 0.7)'
            }
        },
        MuiMenuItem:{
            gutters: {
                color: '#000'
            }
        }
    }
});

export default theme;
