import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUser } from '../../../../redux/actions/user-action';
import { updateLicense } from '../../../../redux/actions/license-action';
// logo import
import Logo from '../../../../assets/logos/ubilod_logo-light_bg.svg';
import LogoDKC from '../../../../assets/logos/dkc.png';
import LogoProfile from '../../../../assets/logos/logo-profile.svg';
// Utils import
import Utils from '../../../../utils/Utils';
// components import
import ChangePasswordDialog from '../../../../components/ui/dialogs/changePasswordDialog/ChangePasswordDialog';
import LicenseDialog from '../../../../components/ui/dialogs/licenseDialog/LicenseDialog';
import Button from "@material-ui/core/Button";
import MenuList from "@material-ui/core/MenuList";
import LanguageDialog from "../../../../components/ui/dialogs/changeLanguageDialog/LanguageDialog";
import {translate} from "../../../../translations/i18n";

const styles  = theme => ({
    appBar: {
        maxHeight : 65,
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin','marginLeft','paddingLeft'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    margin: {
        margin: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 3,
    },
    appBarShift: {
        marginLeft: 290,
        paddingLeft : 16,
        width: `calc(100% - 290px)`,
        transition: theme.transitions.create(['width', 'margin','marginLeft','paddingLeft'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
    },
    badge:{
        width:18,
        height:18,
        background: theme.palette.danger.dark,
        fontWeight: 600,
    },
    hide: {
        display: 'none',
    },
    /* Avatar */
    avatar: {
        cursor:'pointer',
        marginRight:20,
        background: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]:{
            marginRight:12,
      }
    },
    avatarShift: {
        cursor:'pointer',
        background: theme.palette.primary.main,
        marginRight: -4
    },
    username:{
        marginRight:20,
        fontSize:14,
        [theme.breakpoints.down('xs')]:{
            display: 'none'
        }
    },
    rightContainer: {
        transform: 'skew(-30deg)',
        width:256,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: -40,
        [theme.breakpoints.down('xs')]:{
            width:220,
        }
        },
    basicBackground: {
        backgroundImage: 'linear-gradient(to top, #797272, #ded4d4)'
    },
    techBackground: {
        backgroundImage: `linear-gradient(to top, ${theme.palette.secondary.light}, ${theme.palette.secondary.light})`
    },
    commercialBackground: {
        backgroundImage: 'linear-gradient(to top, #0085bf, #0085bf)'
    },
    licenseTitle: {
        fontWeight:'bold',
        marginRight: 10,
        color:'#fff!important',
        minWidth: 112
    },
    licenseMenuItem: {

    },
    usernameMobile:{
        fontSize:12,
        display: 'none',
        padding: '8px 20px',
        [theme.breakpoints.down('xs')]:{
            display: 'block'
        }
    },
    root : {
        flex : 1,
        paddingLeft: 56,
        transition: theme.transitions.create(['paddingLeft'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    rootOpen : {
        flex : 1,
        paddingLeft: 0,
        transition: theme.transitions.create(['paddingLeft'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }
});

class HeaderAppBar extends Component {

    constructor(props){
        super(props);
        this.state = {
            anchorProfileEl: null,
            anchorLicenseEl: null,
            anchorNotificationEl: null,
            openLanguageDialog: false,
        };
    }

    updateLicense = (license_code) => {
        this.setState({anchorLicenseEl: null});
        const license = this.props.user.company.licenses.find((item) => {return item.license === license_code});
        if (license){
            this.props.onUpdateLicense(license);
        }
    };

    render() {
        const { user, license, classes, } = this.props;
        const { anchorProfileEl, anchorLicenseEl} = this.state;

        const licenses = user.company && user.company.licenses.map((item) => (
            (item.license !== license.license)?
                <Link to={'/dashboard'}>
                <MenuItem className={classes.licenseMenuItem}
                          onClick={() => this.updateLicense(item.license)}>
                    {item.license.toUpperCase()}
                </MenuItem>
                </Link>
                :null
        ));

        const backgroundLicense = () => {
            switch (license.license) {
                case 'tech':
                        return classes.techBackground;
                case 'commercial':
                        return classes.commercialBackground;
                default: return classes.basicBackground;
            }
        };

        return (
            <AppBar
                position="fixed"
                className={classNames(classes.appBar, {
                    [classes.appBarShift]: this.props.open,
                })}>

                <Toolbar disableGutters={!this.props.open} style={(this.props.open) ? {paddingLeft : 96} : {} }>
                    <IconButton
                        color="primary"
                        onClick={() => this.props.handleDrawer()}
                        className={classNames(classes.menuButton, {
                            [classes.hide]: this.props.open,
                        })}>
                        <Icon>menu</Icon>
                    </IconButton>
                    <div
                        className={classNames(classes.root, {
                            [classes.rootOpen]: this.props.open,
                        })}
                    >
                        <Link to={'/'}>
                            <Hidden smDown>
                                <img alt={'ubilod-logo'} src={(this.props.user &&
                                    (this.props.user.username === 'dkc'
                                        ||
                                        (this.props.user.company && this.props.user.company.business_name === 'DKC Tech')))
                                    ?
                                    LogoDKC
                                    :
                                    Logo
                                }
                                     width={150} style={{marginTop:4}} />
                            </Hidden>
                            <Hidden mdUp>
                                <img alt={'ubilod-logo'} src={LogoProfile} width={50} style={{marginTop:4}} />
                            </Hidden>
                        </Link>
                    </div>

                    <div className={[classes.rightContainer, backgroundLicense()].join(' ')}>

                    </div>
                        {(license.license) ?
                            <Button onClick={(e) => user.company.licenses.length > 1 && this.setState({ anchorLicenseEl: e.currentTarget })}
                                    disabled={user.company.licenses.length < 2}
                                    className={classes.licenseTitle}>
                                {license.license}
                                {(user.company.licenses.length > 1) ?
                                        <Icon style={{color:'#fff', fontSize:16}}>keyboard_arrow_down</Icon>
                                    : null}
                            </Button>
                            : <Button disabled={true}
                                      className={classes.licenseTitle}>
                                Basic
                            </Button>
                        }

                    <Menu
                        id="license-menu"
                        anchorEl={anchorLicenseEl}
                        open={Boolean(anchorLicenseEl)}
                        onClose={() => this.setState({anchorLicenseEl: null})}>
                        <MenuList onClick={() => this.setState({anchorLicenseEl:undefined})}>
                            {licenses}
                        </MenuList>
                    </Menu>

                        {/* ================ Profile Menu ================ */}
                        <Avatar className={classNames(classes.avatar, {
                            [classes.avatarShift]: this.props.open,
                        })}

                                aria-owns={anchorProfileEl ? 'profile-menu' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => this.setState({ anchorProfileEl: e.currentTarget })}>
                            {Utils.getUsernameShort(this.props.user.username)}
                    </Avatar>

                    <Menu
                        id="profile-menu"
                        anchorEl={anchorProfileEl}
                        open={Boolean(anchorProfileEl)}
                        onClose={() => this.setState({ anchorProfileEl: null })}>
                        <Typography color="inherit" style={{fontWeight:'bold', padding:'0 20px',marginTop:12}}>Account</Typography>
                        <Typography color="inherit" className={classes.username} style={{textAlign:'start', padding:'12px 20px', width:'100%'}}>
                            {this.props.user.username}
                        </Typography>
                        <Divider/>
                        {
                            (this.props.user.company && license.license) ? (
                                <MenuItem onClick={() => this.setState({openLicenseDialog:true})}
                                          onClose={() => this.setState({openLicenseDialog:false})}>
                                    <Icon color="primary" style={{fontSize:18, marginRight:4}}>how_to_reg</Icon>

                                    {translate(`info.license`,{},'Licenza')}
                                </MenuItem>
                            ) : null
                        }

                        <MenuItem onClick={() => {this.setState({openLanguageDialog:true})}}>
                            <Icon color="primary" style={{fontSize:18, marginRight:4}}>language</Icon>
                            {translate(`info.language`,{},'Cambia lingua')}
                        </MenuItem>

                        <MenuItem onClick={() => {this.setState({openChangePasswordDialog:true})}}>
                            <Icon color="primary" style={{fontSize:18, marginRight:4}}>lock</Icon>
                            {translate(`info.password`,{},'Cambia password')}
                        </MenuItem>
                        <MenuItem onClick={() => {
                            this.props.onUpdateUser({});
                            this.props.onUpdateLicense({});
                            window.location = '/';
                        }}>
                            <Icon color="primary" style={{fontSize:18, marginRight:4}}>power_settings_new</Icon>
                            {translate(`info.exit`,{},'Esci')}
                        </MenuItem>
                    </Menu>
                    {/* ================ License Menu ================ */}

                </Toolbar>

                {/* CHANGE PASSWORD DIALOG */}
                {(this.state.openChangePasswordDialog)?
                    <ChangePasswordDialog onClose={() => this.setState({openChangePasswordDialog:false})}/>
                :null}

                {/* LICENSE DIALOG */}
                {(this.state.openLicenseDialog) ?
                    <LicenseDialog open={true}
                                   onClose={() => this.setState({openLicenseDialog:false})}/>
                :null}

                {this.state.openLanguageDialog &&
                    <LanguageDialog
                        title={
                            translate(`info.dialog.title`,{},'Cambia lingua interfaccia')
                        }
                        open={true}
                        confirmBtnText={
                            translate(`info.dialog.confirm`,{},'Conferma')
                            }
                        onClose={() => this.setState({openLanguageDialog:false})}/>
                }
            </AppBar>
        )
    }
}


HeaderAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
    api_control: state.api_control,
});


const mapActionsToProps = {
    onUpdateLicense : updateLicense,
    onUpdateUser : updateUser
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(HeaderAppBar);
