import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
// Toolbar
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const styles  = theme => ({
    root:{
        height: 52,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '104%',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            height: 90
        },
        [theme.breakpoints.down('xs')]:{
            borderRadius: 2,
            height: 90
//boxShadow: 'inset -8px 0 5px -4px rgba(210, 210, 210, .5)',
            //webkitBoxShadow: 'inset -8px 0 5px -4px #e6e6e6',
        }
    },
    containerScrollable:{
        overflowX :'auto',
        display: 'flex',
        position:'absolute',
    },
    toolbarBtn:{
        fontSize: 14,
        padding: '4px 24px',
        borderRadius: 8,
        fontWeight: 'bold',
        marginRight:16,
        minHeight:20,
        lineHeight: 'unset',
        '& > span:first-child':{
            textTransform:'capitalize',
        },
        [theme.breakpoints.down('xs')]:{
            padding: '6px 18px',
        }
    },
    /* Button styles */
    successBtn: {
        color: theme.palette.getContrastText(theme.palette.success.main),
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },

    },
    warningBtn: {
        color: theme.palette.getContrastText(theme.palette.warning.main),
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    dangerBtn: {
        color: theme.palette.getContrastText(theme.palette.danger.main),
        backgroundColor: theme.palette.danger.main,
        '&:hover': {
            backgroundColor: theme.palette.danger.dark,
        },
        '& span:first-child':{
            color: '#fff'
        }
    },
    infoBtn: {
        color: theme.palette.getContrastText(theme.palette.info.main),
        backgroundColor:theme.palette.info.main,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
        },
    },
});

class FunctionsToolbar extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){}

    /* Return the className from the props variant */
    getClassNameFromVariant(variant){
        const classes = this.props.classes;
        switch(variant){
            case 'success': return classes.successBtn;
            case 'warning': return classes.warningBtn;
            case 'danger': return classes.dangerBtn;
            case 'info': return classes.infoBtn;
            default: return '';
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.containerScrollable}>
                {this.props.items.map((item, index) =>
                    (index === 0)?
                        <Button color="primary"
                                variant="contained"
                                disabled={item.disabled}
                                onClick={() => (item.func)?item.func():null}
                                className={classNames(classes.toolbarBtn,classes.margin, this.getClassNameFromVariant(item.variant))}>
                            {item.icon && item.icon !== 'undefined' ? typeof item.icon !== 'function' ?
                                <Icon color="secondary" style={{fontSize:18, margin: '4px 6px 8px 4px'}}>{item.icon}</Icon>
                                :
                                item.icon({margin: '4px 6px 8px 4px',width: 20,height: 20})
                                :
                                null}
                            <span style={{margin : '4px 0px'}}>
                            {item.label}
                            </span>
                        </Button>
                    :
                        <Button color="primary"
                                variant="outlined"
                                disabled={item.disabled}
                                onClick={() => (item.func)?item.func():null}
                                className={classNames(classes.toolbarBtn,classes.margin, this.getClassNameFromVariant(item.variant))}
                                style={(index === this.props.items.length -1)?{marginRight:0}:null}>
                            {item.icon && item.icon !== 'undefined' ? typeof item.icon !== 'function' ?
                                <Icon color="primary" style={{fontSize:18, margin: '4px 6px 8px 4px'}}>{item.icon}</Icon>
                                :
                                item.icon({margin: '4px 6px 8px 4px',width: 20,height: 20})
                                :
                                null}
                            <span style={{margin : '4px 0px'}}>
                            {item.label}
                            </span>
                        </Button>
                )}
                </div>
            </div>
        )
    }
}


FunctionsToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    variant: PropTypes.string, // [success, warning, danger, info]
};

export default withStyles(styles, {withTheme: true})(FunctionsToolbar);
