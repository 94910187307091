import React from "react";
import Dashboard from "./views/dashboard/Dashboard";
import ModelsManager from "./views/modelsManager/ModelsManager";
import ModelDetail from "./views/modelsManager/ModelDetail";
import ModelCategoryFiles from "./views/modelsManager/ModelCategoryFiles";
import TermsAndConditions from "../policy/termsAndConditions/TermsAndConditions";
import PageNotFound from "../error/404/PageNotFound";
import MachineDetail from "./views/machinesManager/MachineDetail";
import FileManager from "./views/machinesManager/fileManager/FileManager";
import MachineContacts from "./views/machinesManager/contactManager/MachineContacts";
import MachineComponents from "./views/machinesManager/componentsManager/MachineComponents";
import MachinePermissionUsers
  from "./views/machinesManager/permissionsManager/MachinePermissionUsers";
import MachinePermissionManager
  from "./views/machinesManager/permissionsManager/MachinePermissionManager";
import MachinesManager from "./views/machinesManager/MachinesManager";
import MachineCategoryFiles from "./views/machinesManager/MachineCategoryFiles";
import CategoriesManager from "./views/categoriesManager/CategoriesManager";
import ContactsManager from "./views/contactsManager/ContactsManager";
import PrivacyAndCookiePolicy from "../policy/privacyAndCookiePolicy/PrivacyAndCookiePolicy";
import AcceptableUsage from "../policy/acceptableUsage/AcceptableUsage";
import RegulationOnIntellectualProperty
  from "../policy/regulationOnIntellectualProperty/RegulationOnIntellectualProperty";
import MachineQrCode from "../common/machineQrCode/MachineQrCode";
import MachineCode from "../common/machineCode/MachineCode";
import BulkImport from "./views/bulkImport/BulkImport"
import RestorePoint from "./views/restorePoint/RestorePoint"

const ApplicationRoutes = [
  {
    // MAIN ROUTE linked on the AppBar
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    breadcrumbs:[{path: '/dashboard'}],
  },
  // Models
  {
    path: "/model",
    name: "Model",
    icon: "",
    component: ModelsManager,
    visible:true,
    breadcrumbs : 'model',
  },
  {
    path: "/model/:model_id",
    name: "Model detail",
    icon: "",
    component: ModelDetail,
    visible:false,
    breadcrumbs : ':model_id',
  },
  {
    path: "/model/:model_id/category/:category_id",
    name: "Category model detail",
    icon: "",
    component: ModelCategoryFiles,
    visible:false,
    breadcrumbs : ':category_id',
  },
  {
    path: "/model/:model_id/:machine_id",
    name: "Machine model detail",
    icon: "",
    component: MachineDetail,
    visible:false,
    breadcrumbs : ':machine_id',
  },
  {
    path: "/model/:model_id/:machine_id/category/:category_id",
    name: "Category id",
    icon: "",
    component: (props) => <MachineCategoryFiles {...props}/>,
    visible:false,
    breadcrumbs : ':category_id',
  },
  {
    path: "/model/:model_id/:machine_id/category/:category_id/file/:file_id",
    name: "Category id",
    icon: "",
    component: FileManager,
    visible:false,
    breadcrumbs : ':file_id',

  },
  {
    path: "/model/:model_id/category/:category_id/file/:file_id",
    name: "Category id",
    icon: "",
    component: FileManager,
    visible:false,
    breadcrumbs : ':file_id',
  },
  {
    path: "/model/:model_id/:machine_id/contacts",
    name: "Machine contact",
    icon: "",
    component: MachineContacts,
    visible:false,
    breadcrumbs : 'contacts'
  },
  {
    path: "/model/:model_id/:machine_id/components",
    name: "Machine components",
    icon: "",
    component: MachineComponents,
    visible:false,
    breadcrumbs : 'components'

  },
  {
    path: "/model/:model_id/:machine_id/permission",
    name: "Machine permission",
    icon: "",
    component: MachinePermissionUsers,
    visible:false,
    breadcrumbs : 'permission'
  },
  {
    path: "/model/:model_id/:machine_id/permission/:user_id",
    name: "Machine permission",
    icon: "",
    component: MachinePermissionManager,
    visible:false,
    breadcrumbs : 'permission'
  },
  // Machine routes
  {
    path: "/machine",
    name: "Machine",
    icon: "",
    component: MachinesManager,
    visible:false,
    breadcrumbs : 'machine'
  },
  {
    path: "/machine/:machine_id",
    name: "Machine detail",
    icon: "",
    component: MachineDetail,
    visible:false,
    public:true,
    breadcrumbs : ':machine_id'
  },
  {
    path: "/machine/:machine_id/category/:category_id",
    name: "Machine category",
    icon: "",
    component: MachineCategoryFiles,
    visible:false,
    public:true,
    breadcrumbs : ':category_id'
  },
  {
    path: "/machine/:machine_id/category/:category_id/file/:file_id",
    name: "Machine file detail",
    icon: "",
    component: FileManager,
    visible:false,
    public:true,
    breadcrumbs : ':file_id'
  },
  {
    path: "/machine/:machine_id/contacts",
    name: "Machine contacts",
    icon: "",
    component: MachineContacts,
    visible:false,
    public:true,
    breadcrumbs : ':contact'
  },
  {
    path: "/machine/:machine_id/components",
    name: "Machine components",
    icon: "",
    component: MachineComponents,
    visible:false,
    public:true,
    breadcrumbs : 'components'
  },
  {
    path: "/machine/:machine_id/permission",
    name: "Machine permissions",
    icon: "",
    component: MachinePermissionUsers,
    visible:false,
    breadcrumbs : 'permission'
  },
  {
    path: "/machine/:machine_id/permission/:user_id",
    name: "Machine permissions",
    icon: "",
    component: MachinePermissionManager,
    visible:false,
    breadcrumbs : 'permission'
  },
  {
    path: "/m",
    name: "Machine scan code",
    icon: "",
    component: MachineCode,
    exact:true,
    visible:false,
    collapse: null,
    public:true,
    breadcrumbs : 'm'
  },
  {
    path: "/m/:machine_code",
    name: "Machine scan code",
    icon: "",
    component: MachineCode,
    exact:true,
    visible:false,
    collapse: null,
    public:true,
    breadcrumbs : 'm'
  },
  {
    path: "/q/:machine_qr_code",
    name: "Machine scan code",
    icon: "",
    component: MachineQrCode,
    exact:true,
    visible:false,
    collapse: null,
    public:true,
  },
  {
    path: "/category",
    name: "Category",
    icon: "",
    component: CategoriesManager,
    visible:false,
    breadcrumbs : 'category'
  },
  {
    path: "/contact",
    name: "Contacts",
    icon: "",
    component: ContactsManager,
    visible:false,
    breadcrumbs : 'contact'
  },
  {
   path: "/bulk_import",
   name: "Importa Link",
   icon: "",
   component: BulkImport,
   visible:false,
   public:true,
   breadcrumbs : 'bulk_import'
  },
  {
   path: "/restore",
   name: "Punti di ripristino",
   icon: "",
   component: RestorePoint,
   visible:false,
   public:true,
   breadcrumbs : 'restore'
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and conditions",
    icon: "",
    component: TermsAndConditions,
    visible:false,
    public:true,
    breadcrumbs : 'terms-and-conditions'
  },
  {
    path: "/privacy-and-cookie-policy",
    name: "Privacy and cookie",
    icon: "",
    component: PrivacyAndCookiePolicy,
    visible:false,
    public:true,
    breadcrumbs : 'privacy-and-cookie-policy'
  },
  {
    path: "/acceptable-usage",
    name: "Acceptable usage",
    icon: "",
    component: AcceptableUsage,
    visible:false,
    public:true,
    breadcrumbs:'privacy-and-cookie-policy'
  },
  {
    path: "/regulation-on-intellectual-property",
    name: "Regulation on intellectual property",
    icon: "",
    component: RegulationOnIntellectualProperty,
    visible:false,
    public:true,
    breadcrumb : 'regulation-on-intellectual-property'
  },
  {
    path: "/404",
    name: "Page not found",
    icon: "",
    component: PageNotFound,
    visible:false,
    public:true,
    breadcrumb : '404'

  },
];

export default ApplicationRoutes;
