import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
// logo import
import Logo from '../../assets/logos/logo-brand-white.svg';
import LogoDKC from '../../assets/logos/dkc.png';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../redux/actions/api-control-action';

const styles  = theme => ({
    appBar: {
        padding:'0 40px',
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarDKC: {
        padding:'0 40px',
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: 290,
        width: `calc(100% - 290px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    button: {
        marginLeft: 12,
        minWidth:100,
    },
    buttonSign:{
        marginLeft: 12,
        minWidth:100,
        border: '1px solid ' + theme.palette.active.main,
        cursor:'pointer'
    },
    buttonSignDKC:{
        color :  theme.palette.active.main,
        marginLeft: 12,
        minWidth:100,
        border: '1px solid ' + theme.palette.active.main,
        cursor:'pointer'
    }
});

class HeaderRoot extends Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount(){}

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                {/* AppBar*/}
                <AppBar
                    position="fixed"
                    className={classNames(( this.props.user && this.props.user.company_id &&  this.props.user.company_id === 103) ? classes.appBarDKC : classes.appBar , {
                        [classes.appBarShift]: this.props.open,
                    })}>
                    <Toolbar disableGutters={!this.props.open}>
                        <div style={{flex:1}}>
                            <Link to={'/'}>
                                <img alt={'ubilod-logo'} src={(this.props.user && ((this.props.user.company_id && this.props.user.company_id === 103) || (this.props.user.company && this.props.user.company.business_name === 'DKC Tech'))) ? LogoDKC : Logo} width={100} style={{marginTop:4}} />
                               {/* <Hidden smDown>
                                    <img alt={'ubilod-logo'} src={Logo} width={112} style={{marginTop:4}} />
                                </Hidden>
                                <Hidden smUp>
                                    <img alt={'ubilod-logo'} src={LogoProfile} width={50} style={{marginTop:4}} />
                                </Hidden>*/}
                            </Link>
                        </div>
                        <Link to={'/login'}>
                            <Button variant="outlined" color={(this.props.user.company_id === 103) ? "primary" : "secondary"} className={(this.props.user.company_id === 103) ? classes.buttonSignDKC : classes.buttonSign}>
                                Login
                            </Button>
                        </Link>
                        {/*<Button disabled variant="contained" color="secondary" className={classes.button}>
                            Registrati
                        </Button>*/}
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}


HeaderRoot.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(HeaderRoot);
